import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Animate } from 'react-simple-animate';
import {
  QuestionDescription,
  QuestionText,
  SelectOption,
  DemoButton,
  ButtonText,
  OptionText,
  FooterBox,
  BrandText,
  BrandNameText,
  NpsTextBox,
  NpsText,
  NpsOption,
  TextInput,
  ToggleButtonGroup,
  ToggleButton,
  GridContainer,
  ButtonContainer,
  ProgressbarPath,
  InnerProgressbar,
  QuestionImgContainer,
  ErrorText,
} from './StyledUtils';
import { IQuestion, surveyTheme } from '../types/survey';

import { ReactComponent as EmptyStarImg } from '../images/empty-star.svg';
import { ReactComponent as FillStarImg } from '../images/fill-star.svg';
import { emojisForFeedback } from '../static/staticData';
import { isRtl, validateDataField } from '../utils/helper.util';
import { ResultComponent } from './quiz/QuizComponent';

const ProgressBarComponent: FunctionComponent<{
  theme: surveyTheme;
  progressBarWidth: number;
}> = ({ theme, progressBarWidth }) => {
  return (
    <ProgressbarPath
      style={{
        backgroundColor: `${theme.highlight_color}26`,
      }}
    >
      <InnerProgressbar
        style={{
          width: `${progressBarWidth}%`,
          backgroundColor: `${theme.highlight_color}`,
        }}
      ></InnerProgressbar>
    </ProgressbarPath>
  );
};

const BrandingComponent: FunctionComponent<{ theme: surveyTheme; showLogo: boolean }> = ({ theme, showLogo }) => {
  return (
    <FooterBox>
      {showLogo && (
        <>
          <BrandText style={{ color: `${theme?.text_color}` }}>powered by </BrandText>{' '}
          <BrandNameText style={{ color: `${theme?.text_color}` }}>Blitzllama</BrandNameText>
        </>
      )}
    </FooterBox>
  );
};

const SurveySelectComponent: FunctionComponent<IQuestion> = ({
  question,
  allQuestionLogo,
  nextScreen,
  theme,
  showLogo,
  showProgressBar,
  progressBarWidth,
  surveyResponse,
  setSurveyResponse,
  languageCode,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const {
    question_img,
    question_text,
    question_desc,
    properties,
    question_type,
    question_id,
    question_order,
    rule_sets,
    cta_text,
    is_mandatory,
    max_selections,
  } = question;

  // scale value is used for maintaining the index to change the background color or selected option
  const { responses, scale_value, survey_input } = surveyResponse;

  const checkIfSubmitAllowed = () => {
    if (question_type === 'single_select_feedback' && responses.length === 0) {
      return true;
    }

    if (responses.some((o) => o.has_text_input) && (survey_input.length === 0 || survey_input.trim().length === 0)) {
      return true;
    }

    return is_mandatory && question_type === 'multi_select_feedback' && responses.length === 0;
  };

  const checkIfExists = (option: string, idx: string) => {
    return responses.some((o) => o._id === idx);
  };

  const toggleOptions = (option_text: string, _id: string, order: number, has_text_input: boolean, key: number) => {
    if (question_type === 'multi_select_feedback') {
      if (checkIfExists(option_text, _id)) {
        const activeOptions = responses.filter((o) => o._id !== _id);
        setSurveyResponse((prevState) => ({
          ...prevState,
          responses: [...activeOptions],
        }));
      } else {
        //Add maximum logic only on add item
        if (responses.length === max_selections) {
          setErrorMessage(`Please choose a maximum of ${max_selections} options.`);
          setTimeout(() => {
            setErrorMessage('');
          }, 2000);
          return;
        }
        responses.push({ option_text, _id, order, has_text_input });
        responses.sort((a, b) => a.order - b.order);
        setSurveyResponse((prevState) => ({
          ...prevState,
          responses: [...responses],
        }));
      }
    } else {
      setSurveyResponse((prevState) => ({
        ...prevState,
        scale_value: key,
        responses: [{ option_text, _id, order, has_text_input }],
      }));
    }
  };

  const tick_color = '23' + `${theme.bg_color}CC`.substring(1);

  return (
    <div>
      {showProgressBar && <ProgressBarComponent theme={theme} progressBarWidth={progressBarWidth} />}

      <QuestionImgContainer>
        {(question_img || allQuestionLogo) && (
          <img
            src={question_img ? question_img : allQuestionLogo ? allQuestionLogo : ''}
            alt=""
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
        )}
      </QuestionImgContainer>

      <QuestionText style={{ color: `${theme?.text_color}`, textAlign: isRtl(languageCode) ? 'right' : 'left' }}>
        {is_mandatory && <span style={{ color: '#FF1B1B' }}>*</span>}
        {question_text}
      </QuestionText>
      <QuestionDescription
        style={{ color: `${theme?.secondary_text_color}80`, textAlign: isRtl(languageCode) ? 'right' : 'left' }}
      >
        {question_desc}
      </QuestionDescription>
      <style>
        {`[type='checkbox']:checked {
  background-color: ${theme?.selected_radio_button_color};outline:none}}`}
      </style>
      <style>
        {`[type='checkbox']:checked::before {  
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%${tick_color}'/%3E %3C/svg%3E");
          }`}
      </style>
      <style>
        {`@media only screen and (max-width: 600px) {   
          [type='checkbox']:checked::before {     
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%${tick_color}'/%3E %3C/svg%3E");  
          } 
        }`}
      </style>
      <style>
        {`[type='checkbox'] {
outline:1px solid ${theme?.radio_button_color}73}`}
      </style>
      <GridContainer>
        {properties &&
          properties?.options &&
          properties?.options.map(({ option_text, _id, order, has_text_input = false }, key) => (
            <div>
              <SelectOption
                key={key}
                onClick={() => {
                  toggleOptions(option_text, _id, order, has_text_input, key);
                }}
                style={
                  (option_text && checkIfExists(option_text, _id)) || scale_value === key
                    ? {
                        backgroundColor: `${theme?.selected_option_bg_color}1A`,
                        border: `1px solid ${theme?.selected_border_color}`,
                      }
                    : {
                        backgroundColor: `${theme?.option_bg_color}CC`,
                        border: `1px solid ${theme?.border_color}`,
                      }
                }
              >
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                  <div style={{ display: 'flex' }}>
                    {_id && <input type="checkbox" checked={checkIfExists(option_text, _id) || scale_value === key} />}

                    <OptionText
                      style={{
                        color:
                          (option_text && checkIfExists(option_text, _id)) || scale_value === key
                            ? `${theme?.selected_text_color}`
                            : `${theme?.text_color}`,
                        fontWeight: (option_text && checkIfExists(option_text, _id)) || scale_value === key ? 700 : 400,
                        marginLeft: isRtl(languageCode) ? 'auto' : '',
                      }}
                    >
                      {option_text}
                    </OptionText>
                  </div>
                  {responses.some((r) => r.has_text_input) && has_text_input && (
                    <div style={{ flexBasis: '100%' }}>
                      <TextInput
                        placeholder={'Please specify'}
                        rows={1}
                        style={{
                          display: 'block',
                          backgroundColor: 'transparent',
                          borderColor: `${theme?.input_border_color}33`,
                          color: `${theme?.text_color}`,
                          textAlign: isRtl(languageCode) ? 'right' : 'left',
                        }}
                        value={survey_input}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          setSurveyResponse((prevState) => ({
                            ...prevState,
                            survey_input: e.target.value,
                          }))
                        }
                      />
                    </div>
                  )}
                </div>
              </SelectOption>
            </div>
          ))}
      </GridContainer>

      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

      <ButtonContainer>
        {(question_type === 'multi_select_feedback' ||
          (question_type === 'single_select_feedback' && responses.length > 0)) && (
          <DemoButton
            disabled={checkIfSubmitAllowed()}
            onClick={() => {
              nextScreen(question_id, question_order, question_type, rule_sets, survey_input, undefined, responses);
            }}
            style={{
              backgroundColor: checkIfSubmitAllowed() ? `${theme?.highlight_color}80` : `${theme?.highlight_color}`,
            }}
          >
            <ButtonText
              style={{
                color: checkIfSubmitAllowed() ? `${theme?.cta_text_color}80` : `${theme?.cta_text_color}`,
              }}
            >
              {cta_text}
            </ButtonText>
          </DemoButton>
        )}
      </ButtonContainer>

      <BrandingComponent showLogo={showLogo} theme={theme} />
    </div>
  );
};

const SurveyRatingComponent: FunctionComponent<IQuestion> = ({
  question,
  allQuestionLogo,
  nextScreen,
  theme,
  showLogo,
  showProgressBar,
  progressBarWidth,
  surveyResponse,
  setSurveyResponse,
  languageCode,
}) => {
  const {
    question_img,
    question_variant,
    question_text,
    question_desc,
    properties,
    question_id,
    question_order,
    question_type,
    rule_sets,
  } = question;

  const isMobile = () => {
    return /mobile|tablet|android|ipad|iphone/i.test(navigator.userAgent.toLowerCase());
  };

  let toggleData = [1, 2, 3, 4, 5];
  let width = isMobile() ? '20%' : '10%';
  let textBoxWidth = isMobile() ? '100%' : '55%';

  if (question_type === 'nps_feedback') {
    toggleData = [0, ...toggleData, 6, 7, 8, 9, 10];
    width = '10%';
    textBoxWidth = '100%';
  }

  const { scale_value } = surveyResponse;

  // get rating box background color
  const getBoxColor = (rating_value: number) => {
    // for nps in link surveys, if question variant is colourised
    if (question_type === 'nps_feedback' && question_variant === 'colourised') {
      if (rating_value >= 0 && rating_value < 7) {
        return '#FF8E8E';
      } else if (rating_value === 7 || rating_value === 8) {
        return '#FFE177';
      } else if (rating_value === 9 || rating_value === 10) {
        return '#6DFFB0';
      }
    }
    // for default nps and opinion scale
    else if (scale_value === rating_value) {
      return `${theme?.selected_range_higlight_color}0D`;
    }
    return `${theme?.range_background_color}`;
  };

  return (
    <div>
      {showProgressBar && <ProgressBarComponent theme={theme} progressBarWidth={progressBarWidth} />}

      <QuestionImgContainer>
        {(question_img || allQuestionLogo) && (
          <img
            src={question_img ? question_img : allQuestionLogo ? allQuestionLogo : ''}
            alt=""
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
        )}
      </QuestionImgContainer>

      <QuestionText style={{ color: `${theme?.text_color}`, textAlign: isRtl(languageCode) ? 'right' : 'left' }}>
        {question_text}
      </QuestionText>
      <QuestionDescription
        style={{ color: `${theme?.secondary_text_color}80`, textAlign: isRtl(languageCode) ? 'right' : 'left' }}
      >
        {question_desc}
      </QuestionDescription>
      <div>
        <ToggleButtonGroup>
          {toggleData.map((option, key) => (
            <ToggleButton
              key={key}
              style={{
                border: `1px solid ${theme?.range_border_color}`,
                borderRadius: '4.2px',
                backgroundColor: getBoxColor(option),
                width: width,
              }}
              onClick={() => {
                setSurveyResponse((prevState) => ({
                  ...prevState,
                  scale_value: option,
                }));
                setTimeout(() => {
                  nextScreen(question_id, question_order, question_type, rule_sets, undefined, option);
                }, 500);
              }}
            >
              <NpsOption
                style={{
                  color: scale_value === option ? `${theme?.selected_range_higlight_color}` : `${theme?.text_color}`,
                }}
              >
                {option}
              </NpsOption>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <NpsTextBox style={{ width: textBoxWidth }}>
          <NpsText
            style={{
              color: `${theme?.text_color}`,
              textAlign: 'left',
            }}
          >
            {properties?.labels?.left}
          </NpsText>
          <NpsText
            style={{
              color: `${theme?.text_color}`,
              textAlign: 'right',
            }}
          >
            {properties?.labels?.right}
          </NpsText>
        </NpsTextBox>
      </div>

      <BrandingComponent showLogo={showLogo} theme={theme} />
    </div>
  );
};

const SurveyInputComponent: FunctionComponent<IQuestion> = ({
  question,
  allQuestionLogo,
  nextScreen,
  theme,
  showLogo,
  showProgressBar,
  progressBarWidth,
  surveyResponse,
  setSurveyResponse,
  languageCode,
}) => {
  const { survey_input } = surveyResponse;
  const [errorMessage, setErrorMessage] = useState('');
  const {
    question_img,
    question_text,
    question_desc,
    question_id,
    question_order,
    question_type,
    rule_sets,
    cta_text,
    cta_link,
    is_mandatory,
    placeholder,
    validations,
  } = question;

  const handleCtaLink = (cta_link: string) => {
    if (cta_link.includes('http://') || cta_link.includes('https://')) {
      return cta_link;
    } else {
      return 'https://' + cta_link;
    }
  };

  const handleInputSubmit = () => {
    if (question_type === 'data_collection') {
      const { goto_next, message } = validateDataField(survey_input, validations, is_mandatory);

      if (!goto_next) {
        setErrorMessage(message);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);

        return;
      }
    }
    nextScreen(question_id, question_order, question_type, rule_sets, survey_input);
  };

  return (
    <div>
      {showProgressBar && <ProgressBarComponent theme={theme} progressBarWidth={progressBarWidth} />}

      <QuestionImgContainer>
        {(question_img || allQuestionLogo) && (
          <img
            src={question_img ? question_img : allQuestionLogo ? allQuestionLogo : ''}
            alt=""
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
        )}
      </QuestionImgContainer>

      <QuestionText style={{ color: `${theme?.text_color}`, textAlign: isRtl(languageCode) ? 'right' : 'left' }}>
        {is_mandatory && <span style={{ color: '#FF1B1B' }}>*</span>}
        {question_text}
      </QuestionText>
      <QuestionDescription
        style={{ color: `${theme?.secondary_text_color}80`, textAlign: isRtl(languageCode) ? 'right' : 'left' }}
      >
        {question_desc}
      </QuestionDescription>
      <style>{`textarea::placeholder {color: ${theme?.text_color}33`}</style>
      <div>
        {(question_type === 'input_feedback' || question_type === 'data_collection') && (
          <TextInput
            placeholder={placeholder}
            rows={1}
            style={{
              backgroundColor: 'transparent',
              borderColor: `${theme?.input_border_color}33`,
              color: `${theme?.text_color}`,
              textAlign: isRtl(languageCode) ? 'right' : 'left',
            }}
            value={survey_input}
            onChange={(e) =>
              setSurveyResponse((prevState) => ({
                ...prevState,
                survey_input: e.target.value,
              }))
            }
          />
        )}

        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      </div>

      {cta_link !== '' ? (
        <a href={handleCtaLink(cta_link)} target="_blank">
          <ButtonContainer>
            <DemoButton
              disabled={is_mandatory && survey_input.length === 0}
              style={
                question_type === 'intro_prompt'
                  ? { backgroundColor: `${theme?.highlight_color}`, marginTop: 0 }
                  : {
                      backgroundColor:
                        is_mandatory && survey_input.length === 0
                          ? `${theme?.highlight_color}80`
                          : `${theme?.highlight_color}`,
                    }
              }
              onClick={() => {
                nextScreen(question_id, question_order, question_type, rule_sets, survey_input);
              }}
            >
              <ButtonText
                style={{
                  color:
                    is_mandatory && survey_input.length === 0
                      ? `${theme?.cta_text_color}80`
                      : `${theme?.cta_text_color}`,
                }}
              >
                {cta_text}
              </ButtonText>
            </DemoButton>
          </ButtonContainer>
        </a>
      ) : (
        <ButtonContainer>
          <DemoButton
            disabled={is_mandatory && survey_input.length === 0}
            style={
              question_type === 'intro_prompt'
                ? { backgroundColor: `${theme?.highlight_color}`, marginTop: 0 }
                : {
                    backgroundColor:
                      is_mandatory && survey_input.length === 0
                        ? `${theme?.highlight_color}80`
                        : `${theme?.highlight_color}`,
                  }
            }
            onClick={() => {
              handleInputSubmit();
            }}
          >
            <ButtonText
              style={{
                color:
                  is_mandatory && survey_input.length === 0 ? `${theme?.cta_text_color}80` : `${theme?.cta_text_color}`,
              }}
            >
              {cta_text}
            </ButtonText>
          </DemoButton>
        </ButtonContainer>
      )}

      <BrandingComponent showLogo={showLogo} theme={theme} />
    </div>
  );
};

const SurveyStarComponent: FunctionComponent<IQuestion> = ({
  question,
  allQuestionLogo,
  nextScreen,
  theme,
  showLogo,
  showProgressBar,
  progressBarWidth,
  surveyResponse,
  setSurveyResponse,
  languageCode,
}) => {
  const { question_img, question_text, question_desc, question_id, question_order, question_type, rule_sets } =
    question;

  const isMobile = () => {
    return /mobile|tablet|android|ipad|iphone/i.test(navigator.userAgent.toLowerCase());
  };

  const star = [1, 2, 3, 4, 5];
  const width = isMobile() ? '20%' : '10%';
  const emojiSize = isMobile() ? '48.34px' : '69.12px';
  const [activeEmoji, setActiveEmoji] = useState(false);

  const { scale_value } = surveyResponse;

  useEffect(() => {
    setActiveEmoji(false);
  }, [question_id]);

  return (
    <div>
      {showProgressBar && <ProgressBarComponent theme={theme} progressBarWidth={progressBarWidth} />}
      <QuestionImgContainer>
        {(question_img || allQuestionLogo) && (
          <img
            src={question_img ? question_img : allQuestionLogo ? allQuestionLogo : ''}
            alt=""
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
        )}
      </QuestionImgContainer>

      <QuestionText style={{ color: `${theme?.text_color}`, textAlign: isRtl(languageCode) ? 'right' : 'left' }}>
        {question_text}
      </QuestionText>
      <QuestionDescription
        style={{
          color: `${theme?.secondary_text_color}80`,
          textAlign: isRtl(languageCode) ? 'right' : 'left',
          marginBottom: '1.9rem',
        }}
      >
        {question_desc}
      </QuestionDescription>
      <div>
        {question_type === 'star_feedback' ? (
          <ToggleButtonGroup>
            {star.map((option, key) => (
              <div
                key={key}
                style={{
                  width: width,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSurveyResponse((prevState) => ({
                    ...prevState,
                    scale_value: key + 1,
                  }));

                  setTimeout(() => {
                    nextScreen(question_id, question_order, question_type, rule_sets, undefined, option);
                  }, 500);
                }}
              >
                {scale_value > key ? <FillStarImg /> : <EmptyStarImg />}
              </div>
            ))}
          </ToggleButtonGroup>
        ) : (
          <ToggleButtonGroup>
            {emojisForFeedback.map(({ value, icon }, key) => (
              <div
                key={key}
                style={{
                  width: width,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setActiveEmoji(true);
                  setSurveyResponse((prevState) => ({
                    ...prevState,
                    scale_value: value,
                  }));

                  setTimeout(() => {
                    nextScreen(question_id, question_order, question_type, rule_sets, undefined, value);
                  }, 500);
                }}
              >
                <Animate
                  play={activeEmoji && scale_value === value}
                  duration={0.5}
                  start={{ transform: 'scale(1)' }}
                  end={{ transform: 'scale(1.3)' }}
                >
                  <img
                    src={icon}
                    style={{
                      width: emojiSize,
                      height: emojiSize,
                      opacity: activeEmoji && scale_value !== value ? 0.3 : '',
                    }}
                  />
                </Animate>
              </div>
            ))}
          </ToggleButtonGroup>
        )}
      </div>

      <BrandingComponent showLogo={showLogo} theme={theme} />
    </div>
  );
};

export const SurveyQuestionHandler = ({
  thanksCard,
  question,
  allQuestionLogo,
  nextScreen,
  showCard,
  theme,
  showLogo,
  showProgressBar,
  progressBarWidth,
  surveyResponse,
  setSurveyResponse,
  languageCode,
  researchType,
  surveyId,
}: IQuestion) => {
  const history = useHistory();
  if (showCard) {
    if (researchType === 'quiz') {
      return <ResultComponent theme={theme} />;
    } else {
      history.push('/thanks', { thanksCard, theme, showLogo });
    }
    return <></>;
  } else {
    const { question_type } = question;
    switch (question_type) {
      case 'intro_prompt':
      case 'input_feedback':
      case 'data_collection':
        return (
          <SurveyInputComponent
            surveyId={surveyId}
            question={question}
            allQuestionLogo={allQuestionLogo}
            nextScreen={nextScreen}
            thanksCard={thanksCard}
            theme={theme}
            showLogo={showLogo}
            showProgressBar={showProgressBar}
            languageCode={languageCode}
            progressBarWidth={progressBarWidth}
            surveyResponse={surveyResponse}
            setSurveyResponse={setSurveyResponse}
          />
        );
      case 'nps_feedback':
      case 'scale_feedback':
        return (
          <SurveyRatingComponent
            surveyId={surveyId}
            question={question}
            allQuestionLogo={allQuestionLogo}
            nextScreen={nextScreen}
            thanksCard={thanksCard}
            theme={theme}
            showLogo={showLogo}
            showProgressBar={showProgressBar}
            languageCode={languageCode}
            progressBarWidth={progressBarWidth}
            surveyResponse={surveyResponse}
            setSurveyResponse={setSurveyResponse}
          />
        );
      case 'multi_select_feedback':
      case 'single_select_feedback':
        return (
          <SurveySelectComponent
            surveyId={surveyId}
            question={question}
            allQuestionLogo={allQuestionLogo}
            nextScreen={nextScreen}
            thanksCard={thanksCard}
            theme={theme}
            showLogo={showLogo}
            showProgressBar={showProgressBar}
            languageCode={languageCode}
            progressBarWidth={progressBarWidth}
            surveyResponse={surveyResponse}
            setSurveyResponse={setSurveyResponse}
          />
        );
      case 'star_feedback':
      case 'emoji_feedback':
        return (
          <SurveyStarComponent
            surveyId={surveyId}
            question={question}
            allQuestionLogo={allQuestionLogo}
            nextScreen={nextScreen}
            thanksCard={thanksCard}
            theme={theme}
            showLogo={showLogo}
            showProgressBar={showProgressBar}
            languageCode={languageCode}
            progressBarWidth={progressBarWidth}
            surveyResponse={surveyResponse}
            setSurveyResponse={setSurveyResponse}
          />
        );
      default:
        return <></>;
    }
  }
};
